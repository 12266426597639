// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/DLnl1A8Za";

const enabledGestures = {gkNTe3o8n: {hover: true}};

const cycleOrder = ["gkNTe3o8n"];

const serializationHash = "framer-vKwYD"

const variantClassNames = {gkNTe3o8n: "framer-v-13riwd4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, m459_4h7t: title ?? props.m459_4h7t ?? "Our Mission", OgiAphDEy: link ?? props.OgiAphDEy} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OgiAphDEy, m459_4h7t, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gkNTe3o8n", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={OgiAphDEy}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-13riwd4", className, classNames)} framer-1h83odx`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"gkNTe3o8n"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"gkNTe3o8n-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-whlcb5"} data-styles-preset={"DLnl1A8Za"} style={{"--framer-text-alignment": "left"}}>Our Mission</motion.p></React.Fragment>} className={"framer-1thtgjv"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"pZqtiRmbt"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={m459_4h7t} variants={{"gkNTe3o8n-hover": {"--extracted-r6o4lv": "var(--token-1339643a-2c51-41f4-85c3-1fa1d2729bd9, rgb(0, 62, 126))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"gkNTe3o8n-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-whlcb5"} data-styles-preset={"DLnl1A8Za"} style={{"--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-1339643a-2c51-41f4-85c3-1fa1d2729bd9, rgb(0, 62, 126)))"}}>Our Mission</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vKwYD.framer-1h83odx, .framer-vKwYD .framer-1h83odx { display: block; }", ".framer-vKwYD.framer-13riwd4 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-vKwYD .framer-1thtgjv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vKwYD.framer-13riwd4 { gap: 0px; } .framer-vKwYD.framer-13riwd4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-vKwYD.framer-13riwd4 > :first-child { margin-left: 0px; } .framer-vKwYD.framer-13riwd4 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 93
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"sWEnDva74":{"layout":["auto","auto"]}}}
 * @framerVariables {"OgiAphDEy":"link","m459_4h7t":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerp9VKL08_o: React.ComponentType<Props> = withCSS(Component, css, "framer-vKwYD") as typeof Component;
export default Framerp9VKL08_o;

Framerp9VKL08_o.displayName = "Dropdown Link";

Framerp9VKL08_o.defaultProps = {height: 32, width: 93};

addPropertyControls(Framerp9VKL08_o, {OgiAphDEy: {title: "Link", type: ControlType.Link}, m459_4h7t: {defaultValue: "Our Mission", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerp9VKL08_o, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})